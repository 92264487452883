export default (sku) => {
  let skuColor = ""
  switch (sku.toUpperCase()) {
    case "SS":
      skuColor = "Sterling Silver"
      break
    case "KW":
    case "10KW":
    case "14KW":
    case "18KW":
      skuColor = "White Gold"
      break
    case "KY":
    case "10KY":
    case "14KY":
    case "18KY":
      skuColor = "Yellow Gold"
      break
    case "KR":
    case "10KR":
    case "14KR":
    case "18KR":
      skuColor = "Rose Gold"
      break
    case "10KT":
    case "14KT":
      skuColor = "tri"
      break
    case "SSKYP":
    case "SS18KYP":
    case "VERMY":
      skuColor = "Yellow Gold Plated"
      break
  }
  return skuColor
}
